.App {
  margin-bottom: 10rem; }

.invoice-table {
  min-width: 1200px;
  margin-left: 20rem; }

@media print {
  .invoice-table {
    margin: 0;
    font-size: 25px; } }
